import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupee } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Payment = () => {
  return (
    <Container id="booking-page">
      <Row className="mb-3">
        <Col className="mt-3" md={7}>
          <Card>
            <Card.Header className="booking-header">Payment Status</Card.Header>
            <Card.Body className="payment-status">
              <FontAwesomeIcon icon={faCheckCircle} color="#16ae83" size="6x" />
              <h4 className="mt-2 mb-2">Payment Successfull!</h4>
              <p>
                Your payment of <FontAwesomeIcon icon={faRupee} color="#dd3751" /> 1450.00 to
                Booking Master
                <br />
                ID:PAY10203 has been proceeded Successfully!.We’ll send you a confirmation Email
                shortly.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-3" md={5}>
          <Card>
            <Card.Header className="booking-header">Booking Summary</Card.Header>
            <Card.Body>
              <p>
                <span className="theme-color">Booking Reference</span> : MB040124-1001
              </p>
              <p>
                <span className="theme-color">Duty Type</span> Airport Transfer : City Drop
              </p>
              <p>
                <span className="theme-color">Airport Name</span> Bengaluru International Airport
                (Terminal 1), Bangalore
              </p>
              <p>
                <span className="theme-color">Drop Location</span> M. G. Road, Bangalore
              </p>
              <p>
                <span className="theme-color">Car Type</span> Etios
              </p>
              <p>
                <span className="theme-color">Pickup Date & Time</span> 01-05-2024 11:00 AM
              </p>
              <h5 className="text-center mt-3 bt-3">
                Total Fare: <FontAwesomeIcon icon={faRupee} color="#dd3751" /> 1450.00
                <br /> ( inclusive of All Taxes )
              </h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Payment;
