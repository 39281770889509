import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Booking = () => {
  return (
    <Container id="booking-page">
      <Row className="mb-3">
        <Col className="mt-3" md={7}>
          <Card>
            <Card.Header className="booking-header">Customer Details</Card.Header>
            <Card.Body>
              <Form method="post">
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Name *" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="email" placeholder="Email *" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="number" placeholder="Mobile *" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Label>Pickup: Bengaluru International Airport (Terminal 1)</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label>Drop: M. G. Road</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Flight Details" />
                    </Form.Group>
                  </Col>
                  <Col sm={12} className="text-right d-grid">
                    <Button variant="primary" id="booknow-btn" size="lg" type="button">
                      Book Now
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-3" md={5}>
          <Card>
            <Card.Header className="booking-header">Booking Summary</Card.Header>
            <Card.Body>
              <p>
                <span className="theme-color">Duty Type</span> Airport Transfer : City Drop
              </p>
              <p>
                <span className="theme-color">Airport Name</span> Bengaluru International Airport
                (Terminal 1), Bangalore
              </p>
              <p>
                <span className="theme-color">Drop Location</span> M. G. Road, Bangalore
              </p>
              <p>
                <span className="theme-color">Car Type</span> Etios
              </p>
              <p>
                <span className="theme-color">Pickup Date & Time</span> 01-05-2024 11:00 AM
              </p>
              <Tabs defaultActiveKey="inclusions" id="incexc-tab">
                <Tab eventKey="inclusions" title="Inclusions">
                  <p>Base Fare and Fuel Charges</p>
                  <p>Driver Allowance</p>
                  <p>GST (5%)</p>
                </Tab>
                <Tab eventKey="exclusions" title="Exclusions">
                  <p>Toll / State tax</p>
                  <p>Parking</p>
                </Tab>
                <Tab eventKey="terms" title="T & C">
                  <p>
                    This package has a FLAT transfer rate i.e. you do not need to pay for any extra
                    KM or Hour charges.
                  </p>
                  <p>
                    All road toll fees will be charged extra and need to be paid to the concerned
                    authorities as per actuals.
                  </p>
                  <p>
                    Airport entry charge, parking charges, state tax if applicable will be charged
                    extra and need to be paid to the concerned authorities as per actuals.
                  </p>
                  <p>Your trip includes one pick up or drop off within city.</p>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Booking;
