import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import noPageImage from "../img/404.jpeg";

const NoPage = () => {
  return (
    <Container>
      <Row>
        <Col md={12} className="text-center">
          <h1 className="display-4">404 - Page Not Found</h1>
          <p className="lead">Oops! The page you are looking for might be in another galaxy.</p>
          <Link to="/">
            <Button variant="primary">Go Home</Button>
          </Link>
        </Col>
        <Col md={12} className="text-center">
          <img src={noPageImage} alt="Not Found" className="img-fluid" />
        </Col>
      </Row>
    </Container>
  );
};

export default NoPage;
