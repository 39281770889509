import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./Layout.css";

const Layout = () => {
  useEffect(() => {
    document.addEventListener("scroll", () => {
      let header = document.querySelector("#main-nav");
      if (window.scrollY > 50) {
        header?.classList.add("trasparent-bg");
      } else {
        header?.classList.remove("trasparent-bg");
      }
    });
  }, []);

  return (
    <>
      <Navbar expand="lg" id="main-nav" sticky="top" scrolling>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              className="d-inline-block align-top BookingMaster-logo"
              alt="BookingMaster"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <NavDropdown title="Mobility solutions" id="basic-nav-dropdown">
                <NavDropdown.Item href="/">Car Rental Services</NavDropdown.Item>
                <NavDropdown.Item href="/">Office Commute</NavDropdown.Item>
                <NavDropdown.Item href="/">Destination Travel</NavDropdown.Item>
                <NavDropdown.Item href="/">Luxury and Vintage Cars on Rental</NavDropdown.Item>
                <NavDropdown.Item href="/">travel technologies</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/">Our Presence</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              <Nav.Link href="tel:+918892102222">
                <FontAwesomeIcon icon={faPhone} color="#37acf4" /> 8892102222
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      <footer id="footer">
        <Container>
          <Row>
            <Col>
              <img
                src={logo}
                className="d-inline-block align-top BookingMaster-logo"
                alt="BookingMaster"
              />
              <p>
                <b>Address</b> : Bangalore
                <br />
                <b>Mobile</b> : <a href="tel:+918892102222">+91 8892102222</a>
                <br />
                <b>Email</b> : <a href="mailto:info@BookingMaster.in">info@bookingmaster.online</a>
                <br />
              </p>
              <p>
                BookingMaster was founded by a team of experienced entrepreneurs who saw a need for
                a reliable and affordable online car rental service in India.
              </p>
            </Col>
            <Col>
              <h5>Links</h5>
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link href="/">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Mobility Solutions</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col>
              <h5>Links</h5>
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Delhi</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Mumbai</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Hyderabad</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Chennai</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Pune</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Kolkata</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col>
              <h5>Links</h5>
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Ahmedabad</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Jaipur</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Chandigarh</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Visakhapatnam</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Noida</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/">Car Rental Gurgaon</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="copyright">
        BookingMaster &copy; 2023 All Rights Reserved. <a href="/">Terms of Use</a> and{" "}
        <a href="/">Privacy Policy</a>{" "}
        <span className="webniter">
          Designed & Maintained By{" "}
          <a target="_blank" href="https://www.webniter.com" rel="noreferrer">
            Webniter
          </a>
        </span>
      </div>
    </>
  );
};

export default Layout;
