import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Layout from "./Components/Layout";
import Home from "./Components/Home";
import SearchResult from "./Components/SearchResult";
import Booking from "./Components/Booking";
import AboutPage from "./Components/AboutPage";
import Contact from "./Components/Contact";
import Payment from "./Components/Payment";
import NoPage from "./Components/NoPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./theme.css";

export default function App() {
  useEffect(() => {
    ReactGA.initialize("G-TJLEJHJCDL");
    // Track page view for the current route
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route exact path="search" element={<SearchResult />} />
          <Route exact path="booking" element={<Booking />} />
          <Route exact path="payment" element={<Payment />} />
          <Route path="about-us" element={<AboutPage />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
