import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupee } from "@fortawesome/free-solid-svg-icons";

import cabImage from "../img/etios.jpg";

import "../theme.css";

const SearchResult = () => {
  const results = [
    { cabType: "Innova" },
    { cabType: "Etios" },
    { cabType: "Duster" },
    { cabType: "Innova" },
    { cabType: "Etios" },
    { cabType: "Duster" },
    { cabType: "Innova" },
    { cabType: "Etios" },
  ];
  return (
    <Container id="search-result">
      <h4 className="my-4">
        Showing 10 Results : <span className="highlight-color">Bangalore</span> to{" "}
        <span className="highlight-color">Mysore</span>
      </h4>
      <Row>
        <Col className="mb-2" md={3}>
          <label>Sort By:</label>
          <Form.Select aria-label="Sort By">
            <option>Price : Low to High</option>
            <option>Price : High to Low</option>
          </Form.Select>
        </Col>
        <Col className="mb-2" md={6} />
        <Col className="mb-2" md={3}>
          <Form.Group className="mb-3">
            <label>
              Price Range <FontAwesomeIcon icon={faRupee} color="#dd3751" /> 1200 to{" "}
              <FontAwesomeIcon icon={faRupee} color="#dd3751" /> 2500
            </label>
            <input type="range" min="1200" max="2500" placeholder="Price Range" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {results.map((cab, index) => (
          <Col key={index} md={3} className="mb-4">
            <Card>
              <Card.Img variant="top" src={cabImage} alt={cab.cabType} />
              <Card.Body className="text-center">
                <Card.Title className="highlight-color">
                  Toyota Etios
                  <br />
                  <small>or equivalent</small>
                </Card.Title>
                <Card.Text>Includes 450 KM</Card.Text>
                <Card.Text>
                  <FontAwesomeIcon icon={faRupee} color="#dd3751" /> 2500
                  <br />
                  <small>Inclusive of GST</small>
                </Card.Text>
                <Button className="book-btn" variant="text">
                  Book Now
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SearchResult;
