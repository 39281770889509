import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bg1 from "../img/drvezilla_bg_1.jpg";
import "./Whychoose.css";

const WhyChooseUs = () => {
  return (
    <Container fluid id="why-choose-us">
      <Row>
        <Col lg={12} className="why-right">
          <img className="d-block w-100 why-choose-bg" src={bg1} alt="WHY CHOOSE US" />
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseUs;
